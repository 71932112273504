
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import {
    defineComponent, PropType, reactive, Ref, ref
} from 'vue';
import { ModelForm } from '@/components/view/super/model-dialog/all.type';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        initForm: {
            type: Object as PropType<ModelForm>,
            required: true
        },
        operateType: {
            type: String as PropType<OperateType>,
            default: 'add'
        }
    },
    setup(props, { emit }) {
        const rules = {
            VersionName: [
                { required: true, message: WordList.RuleVersionModelVersionNameEmpty, trigger: 'blur' }
            ],
            VersionNumber: [
                { required: true, message: WordList.RuleVersionModelVersionNumberEmpty, trigger: 'blur' }
            ]
        };
        const modelForm: Ref<any> = ref();

        const formData = reactive({ ...props.initForm });

        const close = () => {
            emit('close');
        };

        const submit = () => {
            modelForm.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post((props.operateType === 'add' ? 'versionmanagea' : 'versionmanagem'), formData, () => {
                        emit('success');
                        close();
                    });
                }
            });
        };

        return {
            formData,
            submit,
            close,
            modelForm,
            rules
        };
    }
});

